import { Card } from '@material-ui/core';
import { FC, useCallback } from 'react';

import { getBaseUrl } from '@core/base-url';
import { t } from '@core/i18n';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { MarketplaceMember } from '@redux/types/marketplace-members/marketplaceMembersTypes';
import { Avatar } from '@shared/components/avatar';
import { Button } from '@shared/components/button';
import { Ellipsis } from '@shared/components/ellipsis';
import { Flex } from '@shared/components/flex';
import { getQueriesAsSearch } from '@shared/utils/common';

import { styles } from './MarketplaceMemberCard.styles';

export interface MarketplaceMemberCardProps extends WithStyles<typeof styles> {
  member: MarketplaceMember;
}

const MarketplaceMemberCardComponent: FC<MarketplaceMemberCardProps> = ({ classes, member }) => {
  const goLettings = useCallback(
    () =>
      (window.location.href = `${getBaseUrl()}#/society/lettings${getQueriesAsSearch({
        agent: member.id,
        agent_string: member.name,
      })}`),
    [member]
  );
  const goSales = useCallback(
    () =>
      (window.location.href = `${getBaseUrl()}#/society/sales${getQueriesAsSearch({
        agent: member.id,
        agent_string: member.name,
      })}`),
    []
  );
  const goAcquisitions = useCallback(
    () =>
      (window.location.href = `${getBaseUrl()}#/society/acquisitions${getQueriesAsSearch({
        agent: member.id,
        agent_string: member.name,
      })}`),
    []
  );

  const navigationCardButtons = [
    { id: 1, text: 'Lettings', onClick: goLettings },
    { id: 2, text: 'Sales', onClick: goSales },
    { id: 3, text: 'Acquisitions', onClick: goAcquisitions },
  ];

  return (
    <Card square classes={{ root: classes.root }}>
      <Flex alignItems="center" wrap="nowrap" direction="column" classes={{ root: classes.cardItem }}>
        <Avatar
          classes={{ root: classes.avatar }}
          image={member.image.url}
          isPro={member.pro}
          name={member.name}
          theme={member.theme}
        />
        <Ellipsis withTooltip={false} text={member.name} classes={{ root: classes.name }} />
        <Ellipsis withTooltip={false} text={member.organisation.name} classes={{ root: classes.organisation }} />

        <Flex alignItems="center" wrap="nowrap" direction="column" className={classes.cardBody}>
          <div className={classes.cardItem}>
            <span>Tel: {member.tel || '-'}</span>
          </div>
          <div className={classes.cardItem}>
            <span>
              {t('mobile')}: {member.mobile || '-'}
            </span>
          </div>

          {member.email ? (
            <a href={`mailto:${member.email}`} className={classes.emailLink}>
              <Ellipsis withTooltip={false} text={member.email} />
            </a>
          ) : (
            <div className={classes.cardItem}>
              <span>{t('coming_soon')}</span>
            </div>
          )}
        </Flex>
      </Flex>
      <Flex className={classes.footer}>
        {navigationCardButtons.map((button) => (
          <Button
            key={`${button.id}_${button.text}`}
            classes={{ root: classes.footerBtn }}
            color="default"
            size="medium"
            text={button.text}
            variant="outlined"
            onClick={button.onClick}
          />
        ))}
      </Flex>
    </Card>
  );
};

export const MarketplaceMemberCard = withStyles(styles)(MarketplaceMemberCardComponent);
